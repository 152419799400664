<template>
  <div class="about">
    <img :src="about.banner" mode="" class="banner" />
    <div class="about-main">
      <div class="about-top">
        <div class="t-a-c">
          <div class="mTop32 fs30 bold title-line">关于我们</div>
        </div>
        <div class="fs18 introduce alCen-jcSB mTop50">
          <div>
            <span class="bold coldc3043">广州和财网络科技有限公司</span>
            ，于2004年8月在广州成立，响应国家号召、认真学习十四五规划指导思想，利用新一代信息技术、为乡村振兴信息化提供专业的解决方案；研发数字乡村相关智慧软件平台、赋能乡村振兴、数字乡村试点方案提供软件开发、设计咨询、实施服务及人工智能、大数据全面解决方案。
          </div>
          <img :src="about.img[3]" class="maLf16" />
        </div>
        <div class="introduce alCen-jcSB fs18 mTop30">
          <img src="../../assets/image/about/6.jpg" alt="" />
          <div class="coldc3043">
            广州和财网络科技有限公司成立二十年以来，致力于服务好三农工作助力乡村振兴、服务好各级政府部门，为数字化国产化升级；提供数字乡村建设方案和智慧城市实施技术支持；公司投入巨资，利用国际先进技术,
            5G互联网、北斗卫星导航技术、大数据、云计算、GIS技术、人工智能和物联网等技术自主研发了多个大型政府智慧软件云平台。
          </div>
        </div>
        <div class="list">
          <div class="t-a-c fs30 bold title-line">
            公司研发的数字乡村、乡村振兴软件平台软件有哪些
          </div>
          <div class="list2 alCen-jcSB wrap mTop50">
            <img src="../../assets/img/index/project.jpg" alt=""/>

<!--            <div-->
<!--              v-for="(item, index) in title_list"-->
<!--              :key="index"-->
<!--              class="list2-item"-->
<!--            >-->
<!--              {{ item.title }}-->
<!--            </div>-->
          </div>
        </div>
      </div>
      <div>
        <div class="fs24 col333 t-a-c bold mTop32 title-line">合作联系</div>
        <div class="t-a-c mTop32 cole00406 fs14">
          我们专注数字乡村，提供咨询、设计、软件开发、信息化整体解决方案，赋能乡村振兴战略实施。
        </div>
        <div class="f-a-j relation mTop32">
          <img :src="company.QRcode" mode="" />
          <div class="fs20">
            <div class="alCen">
              <img src="../../assets/image/about/12.png" class="icon" />
              <div><span class="bold">联系人：</span>叶先生</div>
            </div>
            <div class="alCen">
              <img src="../../assets/image/about/15.png" class="icon" />
              <div><span class="bold">联系电话：</span>{{ company.phone }}</div>
            </div>
            <div class="alCen">
              <img src="../../assets/image/about/13.png" class="icon" />
              <div>
                <span class="bold">全国服务热线：</span
                >{{ company.telephone_none_line + "/" + company.telephone2 }}
              </div>
            </div>
            <div class="alCen">
              <img src="../../assets/image/about/14.png" class="icon" />
              <div><span class="bold">邮箱：</span>{{ company.email }}</div>
            </div>
            <div class="alCen">
              <img src="../../assets/image/about/11.png" class="icon" />
              <div><span class="bold">地址：</span>{{ company.address }}</div>
            </div>
          </div>
        </div>
      </div>
      <img src="../../assets//image/about/6.png" class="safeguard" />
      <div class="about-bottom f-a-j mTop40">
        <div class="about-bottom-list">
          <div v-for="(item, index) in left_list" :key="index" class="fs16">
            <span class="fs10 coldc3043">◆ </span>{{ item.title }}
          </div>
        </div>
        <img src="../../assets//image/about/5.png" />
        <div class="about-bottom-list">
          <div v-for="(item, index) in right_list" :key="index" class="fs16">
            <span class="fs10 coldc3043">◆ </span>{{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about",
  components: {},
  data() {
    return {
      about: this.$store.state.about,
      company: this.$store.state.company,

      left_list: [
        {
          title: "独立稳定的云计算中心服务器",
        },
        {
          title: " 驻全国各地多个办事处，快速响应客户需求",
        },
        {
          title: "提供7*24小时远程技术支持和现场技术服务",
        },
        {
          title: "接到故障报告后工程师在2小时内响应并提出解决方案",
        },
        {
          title: "提供技术手册、产品说明等资料供客户使用",
        },
      ],
      right_list: [
        {
          title: "提供公司官网在线业务咨询、故障申告、网络优化等服务",
        },
        {
          title: " 拥有完善的行业技术一篮子解决方案库供客户参考",
        },
        {
          title: "拥有专业的技术支持团队及资深客户培训团队",
        },
        {
          title: "具有强硬的研发队伍作为一线售后服务的坚强后盾",
        },
        {
          title: "拥有众多典型客户案例供学习参考选型",
        },
      ],
      title_list: [
        { title: "数字乡村一张图管理平台" },
        { title: "乡村振兴大数据平台" },
        { title: "数字政府" },
        { title: "农村人居环境监测平台" },
        { title: "乡村综合治理平台" },
        { title: "农村产权交易平台" },
        { title: "宅基地流转平台" },
        { title: "数字乡村综合服务平台" },
        { title: "平安城市" },
        { title: "精准扶贫管理平台" },
        { title: "农村远程教育平台" },
        { title: "远程医疗平台" },
        { title: "农村电商平台" },
        { title: "四好农村公路管理养护系统" },
        { title: "智慧交通" },
        { title: "智慧公路养护系统" },
        { title: "路长制平台" },
        { title: "林长制平台" },
        { title: "智慧农田监测平台" },
        { title: "智慧农业平台" },
        { title: "智慧环卫" },
        { title: "智慧城管" },
        { title: "智慧应急指挥调度系统" },
        { title: "森林防火指挥中心" },
        { title: "智慧医院软件" },
        { title: "智慧河道管理" },
        { title: "智慧生态环境监测系统" },
        { title: "政法跨部门大数据办案" },
        { title: "无人机反制系统" },
        { title: "警务保障一体化综合应用" },
        { title: "教育局事业单位内控" },
        { title: "人大预算监督系统" },
        { title: "行政事业单位全面预算绩效管理系统" },
        { title: "智慧区块链系统" },
        { title: "政务网络安全等平台" },
        { title: "赋能乡村振兴全面实施建设" },
      ],
    };
  },
  watch: {},

  methods: {},
  mounted() {
    document.title = "关于我们";
  },
};
</script>

<style lang="less" scoped>
@import url("./about.less");
</style>
